import { getTrimValue } from "./commonService";

// #region Constants
export const month_list = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DISPLAY_DATE = {
  DAY_MONTH_YEAR: 1,
  MONTH_DAY_YEAR: 2,
  DAY_SHORT_MONTH: 3,
  DAY_MONTH_SHORTYEAR: 4,
  DAY_SHORT_MONTH_YEAR: 5,
  YEAR_MONTH_DAY: 6,
  DAY_MONTH: 7,
};

export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// #endregion

// #region Helper function

function appendZero(data) {
  return data < 10 ? `0${data}` : data;
}

export const getMonthName = (month) => {
  return month_list[month];
};

export const getShortMonthName = (month) => {
  return getMonthName(month).substr(0, 3);
};

// Return last two digit of year.
const getSmallYear = (year) => {
  return year % 100;
};

export const isTodaysDate = (date = new Date()) => {
  const today = new Date();
  const newDate = new Date(date);
  return isDateSame(today, newDate);
};

export function isDateSame(date1, date2) {
  try {
    if (date1 && date2) {
      date1 = new Date(date1);
      date2 = new Date(date2);
      return date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0);
    }
  } catch (error) {
    return false;
  }
}

export const getDateWithoutTime = (date1) => {
  try {
    if (date1) return date1.setHours(0, 0, 0, 0);
  } catch (error) {
    return false;
  }
};

// #endregion

// #region Manipulations

export function convertUTCDateToLocalDate(date1) {
  return new Date(`${date1}Z`);
}

export function getUTCDateFormat(date1) {
  try {
    if (date1) {
      date1 = convertUTCDateToLocalDate(date1);
      return new Date(
        date1.getUTCFullYear(),
        date1.getUTCMonth(),
        date1.getUTCDate(),
        date1.getUTCHours(),
        date1.getUTCMinutes(),
        date1.getUTCSeconds(),
        date1.getUTCMilliseconds(),
      );
    }
  } catch (error) {
    return "";
  }
}

export function addYears(date, years) {
  try {
    if (date) {
      const newDate = new Date(date);
      newDate.setFullYear(newDate.getFullYear() + years);
      return newDate;
    }
  } catch (error) {
    return "";
  }
}

// Return time with format "hh:mm AM/PM"
export const getDateTime = (date) => {
  try {
    let hours = date.getHours();
    const ampm = hours < 12 ? " AM" : " PM";
    hours = hours <= 12 ? hours : hours - 12;
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}${ampm}`;
  } catch (error) {
    return "";
  }
};

// return time in 24hr format
export const convertTime12to24 = (time12h) => {
  if (time12h) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (modifier?.toUpperCase() === "PM" && parseInt(hours, 10) < 12) {
      hours = parseInt(hours, 10) + 12;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }
    return `${hours}:${minutes ? minutes : "00"}`;
  } else {
    return time12h;
  }
};

// change dd/mm/yyyy to mm/dd/yyyy
export function swapDayAndMonth(dateStr, seperator = "/") {
  const parts = dateStr.split(seperator);
  return parts[1] + seperator + parts[0] + seperator + parts[2];
}

export function checkIfDateIsValid(dateStr, seperator = "/") {
  const dateSplit = dateStr.split(seperator);
  const dd = dateSplit[0];
  const mm = dateSplit[1];
  const yyyy = dateSplit[2];
  if (dd === "31" && (mm === "04" || mm === "06" || mm === "09" || mm === "11"))
    return 0;
  // 31st of a month with 30 days
  if (dd >= "30" && mm === "02") return 0;
  // February 30th or 31st
  if (
    mm === "02" &&
    dd === "29" &&
    !(
      Number(yyyy) % 4 === 0 &&
      (Number(yyyy) % 100 !== 0 || Number(yyyy) % 400 === 0)
    )
  )
    return 0;
  // February 29th outside a leap year
  return 1; // Valid date
}

export function getTimeDifferenceInSeconds(date1, date2) {
  // Convert both dates to milliseconds
  const date1_ms = new Date(date1).getTime();
  const date2_ms = new Date(date2).getTime();
  // Calculate the difference in milliseconds
  const difference_ms = date2_ms - date1_ms;
  // take out milliseconds
  if (isNaN(difference_ms) === false) {
    return difference_ms / 1000;
  }
}

export function getTimeDifferenceFromCurrentInSeconds(date) {
  try {
    return getTimeDifferenceInSeconds(date, new Date());
  } catch (error) {
    return "";
  }
}

/**
 * Convert duration given timeSpan in minutes format to '2h 30m' format.
 * @param {any} timeSpan
 */
export function convertMinutesToHoursMinutes(timeSpan) {
  if (!timeSpan) return null;
  const totalDuration = Number.parseInt(timeSpan);
  const hours = Number.parseInt(totalDuration / 60);
  const minutes = Number.parseInt(totalDuration % 60);

  let duration = hours > 0 ? `${hours}h ` : "";
  duration += minutes > 0 ? `${minutes}m` : "";

  return getTrimValue(duration);
}

// #endregion

// #region Get Formatted Date

// Converts dd/mm/yyyy to date format
export function toDate(dateStr, seperator = "/", hr = "", min = "", sec = "") {
  if (dateStr) {
    if (dateStr instanceof Date && !isNaN(dateStr)) return dateStr;
    const parts = dateStr.split(seperator);
    if (Math.floor(parts[2] / 100)) {
      return new Date(parts[2], parts[1] - 1, parts[0], hr, min, sec);
    }
    const century = Math.floor(new Date().getFullYear() / 100);
    return new Date(century + parts[2], parts[1] - 1, parts[0], hr, min, sec);
  }
}

export function getUTCShortDateMonth(date, getMonthDate = false) {
  const newDate = convertUTCDateToLocalDate(date);
  if (getMonthDate)
    return newDate && !isNaN(newDate)
      ? `${month_list[newDate.getUTCMonth()].substr(
          0,
          3,
        )} ${newDate.getUTCDate()}`
      : "";
  return newDate && !isNaN(newDate)
    ? `${newDate.getUTCDate()} ${month_list[newDate.getUTCMonth()].substr(
        0,
        3,
      )}`
    : "";
}

// Converts the date to a string with format dd/MM/yyyy
export function getDateWithSlash(date) {
  try {
    if (date) {
      const dateObject = new Date(date);
      return `${appendZero(dateObject.getDate())}/${appendZero(
        dateObject.getMonth() + 1,
      )}/${dateObject.getFullYear()}`;
    }
    return "";
  } catch (error) {
    return "";
  }
}

// Return timeline display date with "DD MMMM hh:mm AM/PM" format.
export const getDisplayDateAndTime = (date, isActivityHistory) => {
  // if current year and the date year is same then exisiting code
  // didn't returned current year for that we have used isActivityHistory flag
  // for getting the current year and for only activity history table

  const localDate = convertUTCDateToLocalDate(date);
  if (
    localDate.getFullYear() == new Date().getFullYear() &&
    !isActivityHistory
  ) {
    return (
      localDate &&
      !isNaN(localDate) &&
      `${localDate.getDate()} ${getMonthName(
        localDate.getMonth(),
      )}, ${getDateTime(localDate)}`
    );
  }
  return (
    localDate &&
    !isNaN(localDate) &&
    `${localDate.getDate()} ${getMonthName(
      localDate.getMonth(),
    )} ${localDate.getFullYear()}, ${getDateTime(localDate)}`
  );
};

// Converts the date to a string with format MM/dd/yyyy
export function getSwapedDateWithSlash(date) {
  try {
    if (date) {
      const dateObject = new Date(date);
      return `${appendZero(dateObject.getMonth() + 1)}/${appendZero(
        dateObject.getDate(),
      )}/${dateObject.getFullYear()}`;
    }
    return "";
  } catch (error) {
    return "";
  }
}

export function getDisplayDate(
  date,
  formatType = DISPLAY_DATE.DAY_MONTH_YEAR,
  appendTime = false,
  seperator = "-",
  showMilitaryTime = true, // 24hr time
) {
  try {
    let result = "";
    if (date) {
      const dateObject = new Date(date);
      switch (formatType) {
        case DISPLAY_DATE.DAY_MONTH_YEAR:
          result = `${dateObject.getDate()} ${getMonthName(
            dateObject.getMonth(),
          )} ${dateObject.getFullYear()}`;
          break;
        case DISPLAY_DATE.MONTH_DAY_YEAR:
          result = `${getMonthName(
            dateObject.getMonth(),
          )} ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
          break;
        case DISPLAY_DATE.DAY_SHORT_MONTH:
          result =
            appendZero(dateObject.getDate()) +
            seperator +
            getShortMonthName(dateObject.getMonth());
          break;
        case DISPLAY_DATE.DAY_MONTH_SHORTYEAR:
          result = [
            appendZero(dateObject.getDate()),
            appendZero(dateObject.getMonth() + 1),
            appendZero(getSmallYear(dateObject.getFullYear())),
          ].join("/");
          break;
        case DISPLAY_DATE.DAY_SHORT_MONTH_YEAR:
          result =
            appendZero(dateObject.getDate()) +
            seperator +
            getShortMonthName(dateObject.getMonth()) +
            seperator +
            getSmallYear(dateObject.getFullYear());
          break;
        case DISPLAY_DATE.YEAR_MONTH_DAY:
          result = [
            dateObject.getFullYear(),
            appendZero(dateObject.getMonth() + 1),
            appendZero(dateObject.getDate()),
          ].join(seperator);
          break;
        case DISPLAY_DATE.DAY_MONTH:
          result = `${dateObject.getDate()} ${getMonthName(
            dateObject.getMonth(),
          )}`;
          break;
      }
      if (appendTime)
        result = `${result} ${
          showMilitaryTime
            ? getHour_Minutes_Second(dateObject)
            : getDateTime(dateObject)
        }`;
    }
    return result;
  } catch (error) {
    return "";
  }
}

export function getHour_Minutes_Second(
  dateObject,
  seperator = ":",
  appendSeconds = false,
) {
  if (dateObject) {
    const date1 = convertUTCDateToLocalDate(dateObject);
    let result =
      appendZero(date1.getUTCHours()) +
      seperator +
      appendZero(date1.getUTCMinutes());

    if (appendSeconds)
      result = result + seperator + appendZero(date1.getUTCSeconds());
    return result;
  }
  return "";
}

export function addDays(date, days) {
  try {
    if (date) {
      const dateObject = new Date(date);
      dateObject.setDate(dateObject.getDate() + days);
      return dateObject;
    }
    return "";
  } catch (error) {
    return "";
  }
}

export function getDayName(date) {
  try {
    if (date) {
      return DAYS[new Date(date).getDay()];
    }
  } catch (error) {
    return "";
  }
}

export function getDaysDifference(fromDate, toDate) {
  try {
    const newFromDate = new Date(fromDate).getTime();
    const newToDate = new Date(toDate).getTime();
    if (newFromDate && newToDate) {
      const Difference_In_Time = newToDate - newFromDate;

      return Difference_In_Time / 86400000; //1000 * 60 * 60 * 24 (milisecond to days)
    }
  } catch (error) {
    return "";
  }
}

export function getDateFormat(date) {
  const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  return newDate;
}

export function customParseInt(val) {
  var returnVal = 0;
  if (isNaN(val) && typeof val == "undefined") {
    returnVal = undefined;
  } else if (!isNaN(val)) {
    returnVal = parseInt(val);
  }

  return returnVal;
}

export function convertStringToDateTime(dateTime) {
  var dateWithYear = dateTime.substr(8, 12);
  var date = dateTime.substr(0, 4);
  var dateFormatted = {
    day: date.substr(0, 2),
    month: date.substr(2, 2),
  };
  var dateStatement =
    dateFormatted.day +
    " " +
    month_list[customParseInt(dateFormatted.month) - 1];
  var timeFormatted = dateTime.substr(4, 2) + ":" + dateTime.substr(6, 2);

  return {
    date: dateFormatted,
    dateStatement: dateStatement,
    time: timeFormatted,
    dateTime: new Date(
      customParseInt(dateWithYear),
      customParseInt(dateFormatted.month) - 1,
      dateFormatted.day,
      timeFormatted.split(":")[0],
      timeFormatted.split(":")[1],
    ),
    dateObject: new Date(
      customParseInt(dateWithYear),
      customParseInt(dateFormatted.month) - 1,
      dateFormatted.day,
    ),
    dateObjectWithYear: new Date(
      customParseInt(dateWithYear),
      customParseInt(dateFormatted.month) - 1,
      dateFormatted.day,
    ),
  };
}

export function dateNotLessThan(fromDate, toDate) {
  if (fromDate && toDate) {
    // Invalid date
    if (
      typeof fromDate.getFullYear !== "function" ||
      typeof toDate.getFullYear !== "function"
    ) {
      console.log("Invalid date format passed to check against notLessThan");
      return false;
    }
    fromDate = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate(),
    );
    toDate = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate(),
    );
    return !(fromDate < toDate);
  }
  return true;
}

// Validate date format (input type - "dd/mm/yy")
export function validateDate(dateStr) {
  const regExp = /^(\d\d?)\/(\d\d?)\/(\d\d)$/;
  const matches = dateStr.match(regExp);
  const maxDate = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // Month wise max date
  let isValid = false;

  if (matches) {
    const date = parseInt(matches[1]);
    const month = parseInt(matches[2]);
    const year = parseInt(matches[3]);

    isValid = month <= 12 && month > 0; // Validate month
    isValid &= date <= maxDate[month] && date > 0; // Validate date

    // Condition for leap year
    const leapYear = year % 400 == 0 || (year % 4 == 0 && year % 100 != 0);
    isValid &= month != 2 || leapYear || date <= 28;
  }

  return isValid;
}

// conver date to specific format "ddMMHHmmyyyy"
export const getNumericDateFormat = (date) => {
  if (typeof date !== "object") {
    date = new Date(date);
  }
  let day = date?.getDate()?.toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based
  let hour = date.getHours().toString().padStart(2, "0");
  let minute = date.getMinutes().toString().padStart(2, "0");
  let year = date.getFullYear();

  let formattedDate = day + month + hour + minute + year;
  return formattedDate;
};

// #endregion

export const formatDate = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const parts = dateString.split("-");
  const year = parts[0];
  const month = months[parseInt(parts[1]) - 1];
  const day = parseInt(parts[2]);

  return `${month} ${day}, ${year}`;
};

// Convert date into specific format "MMM DD, YYYY - HH:mm:ss"
export const convertDateToMonthYear = (date) => {
  // Convert string to Date object
  var dateObject = new Date(date);

  // Format the Date object as 'MMM DD, YYYY - HH:mm:ss'
  var formattedDateTime =
    dateObject.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }) +
    " - " +
    dateObject.toLocaleTimeString("en-US", { hour12: false });

  // Milliseconds should not come in the string i.e :00
  return formattedDateTime.substring(0, formattedDateTime.length - 3);
};

// #endregion
